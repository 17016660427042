.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;100;700&display=swap");

:root {
  --primary-font: "Kamerik205Bold", sans-serif;
  --background-color: #181b20;
  --grey-color: #a5a5a5;
  --grey-color-2: #767474;
  --light-grey-color: #d9d9d9;
  --purple-color: #ab73ee;
  --salmon-color: #f0bca6;
  --tertiary-color: #333;
  --quartiary-color: #333;
  --background-color-bis: #1d2127;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

.kamerik-font {
  font-family: "Kamerik205Bold", sans-serif;
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homepage-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 75vh;
  width: 100%;

  justify-content: center;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.mobile-navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  color: white;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
}

.menu-items-container {
  display: flex;
  flex-direction: column;
}

.menu-items-container-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.menu-items-container-mobile-bottom {
  display: flex;
  flex-direction: row;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.profile-widget-component-mobile {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  align-self: center;
  padding-top: 20px;
}

.navbar-top-group {
  margin-top: 10px;
  height: 90%;
}

.homepage-content-mobile {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 90vh;
  width: 100%;

  justify-content: center;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.pricing-content-mobile {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 90vh;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-start;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.library-content-mobile-v2 {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 90vh;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.signup-content {
  display: flex;
  flex-direction: row;
  /* margin-top: 10vh; */
  /* padding-top: 10vh; */
  /* background-color: #181b20; */
  color: #a5a5a5;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.signup-content-mobile {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  /* padding-top: 10vh; */
  background-color: #181b20;
  color: #a5a5a5;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.homepage-content::-webkit-scrollbar {
  display: none;
}

.generate-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 75vh;
  width: 100%;
  /* padding-left: 80px; */
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.generate-content-bis {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 75vh;
  width: 100%;
  /* padding-left: 80px; */
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.learnmore-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 75vh;
  width: 100%;
  /* padding-left: 80px; */
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.library-content {
  display: flex;
  flex-direction: column;
  /* margin-top: 10vh; */
  padding-top: 10vh;
  background-color: #181b20;
  color: #a5a5a5;
  height: 75vh;
  width: 100%;
  /* padding-left: 80px; */
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  align-self: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@font-face {
  font-family: "Kamerik205Bold";
  src: url("../src/fonts/Kamerik205W00Bold.woff2") format("woff2"),
    /* Modern browsers */ url("../src/fonts/Kamerik205W00Bold.woff")
      format("woff"); /* Older browsers */
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: var(--primary-font);
}

.styledButtonEmpty {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 16px;
  color: var(--grey-color);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 30px;
  /* transition: background-color 0.2s, transform 0.2s; */
  width: 150px;
  height: 40px;
  font-family: var(--primary-font);
}

.styledButtonEmpty:hover {
  background-color: var(--purple-color);
  color: var(--light-grey-color);
}

.styledButtonEmpty:active {
  color: var(--grey-color); /* Change la couleur du texte au survol/clic */
  background-color: var(
    --grey-color-2
  ); /* Change le fond au survol/clic, si désiré */
}

.styledButtonEmptyMobile {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 14px;
  color: var(--grey-color);
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 30px;
  /* width: auto; */
  width: 70%;
  height: 30px;
  font-family: var(--primary-font);
}

.styledButtonEmptyMobile:hover {
  background-color: var(--purple-color);
  color: var(--light-grey-color);
}

.styledButtonEmptyMobile:active {
  color: var(--grey-color); /* Change la couleur du texte au survol/clic */
  background-color: var(
    --grey-color-2
  ); /* Change le fond au survol/clic, si désiré */
}

.styledButton {
  background-color: var(--light-grey-color);
  font-size: 16px;
  color: var(--grey-color-2);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.2s, transform 0.2s;
  width: 150px;
  font-family: var(--primary-font);
}

.styledButton:hover {
  background-color: var(--purple-color);
  color: var(--light-grey-color);
}

.styledButton:active {
  transform: scale(0.98);
}

.styledButtonMobile {
  background-color: var(--light-grey-color);
  font-size: 14px;
  color: var(--grey-color-2);
  padding: 5px 20px;
  border: none;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.2s, transform 0.2s;
  width: 70%;
  height: 30px;
  font-family: var(--primary-font);
}

.styledButtonMobile:hover {
  background-color: var(--purple-color);
  color: var(--light-grey-color);
}

.styledButtonMobile:active {
  transform: scale(0.98);
}

.styledButtonDropdown {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 16px;
  color: var(--purple-color);
  cursor: pointer;
  border-radius: 30px;
  width: 200px;
  height: 40px;
  font-family: var(--primary-font);
  text-align: center; /* Centre le texte dans le select, mais pas dans les options */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.styledButtonDropdownMobile {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 12px;
  color: var(--purple-color);
  cursor: pointer;
  border-radius: 30px;
  padding: 5px 15px;
  width: auto;
  height: auto;
  font-family: var(--primary-font);
  text-align: center; /* Centre le texte dans le select, mais pas dans les options */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-text-area {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 18px;
  width: 790px;
  height: 140px;
  background-color: #2a3038;
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
}

.input-text-area-mobile {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 12px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 140px;
  background-color: #2a3038;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
}

.input-text-area-small {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 18px;
  width: 260px;
  height: 40px;
  line-height: 40px;
  background-color: #2a3038;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  border: 1px solid var(--purple-color);
  padding: 0;
  padding-left: 10px;
}

.input-text-area-small-no-border {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 18px;
  /* width: 260px; */
  height: 40px;
  line-height: 40px;
  background-color: #2a3038;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  /* border: 1px solid var(--purple-color); */
  padding: 0;
  padding-left: 10px;
}

.input-text-area-small-no-border-mobile {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 14px;
  height: 45px;
  line-height: 30px;
  background-color: #2a3038;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  padding: 0;
  padding-left: 10px;
}

.input-text-area-result {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 18px;
  width: 100%;
  height: 140px;
  background-color: #2a3038;
  border: none;
  border: 2px solid var(--purple-color);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  text-align: start;
}

.stripe-logo {
  height: 40px;
}

.stripe-logo-mobile {
  height: 35px;
}

.input-text-area-result-mobile {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 12px;
  width: 100%;
  height: 140px;
  background-color: #2a3038;
  border: none;
  border: 2px solid var(--purple-color);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  text-align: start;
}

.input-text-title-result {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 18px;
  width: 100%;
  height: 40px;
  background-color: #2a3038;
  border: none;
  border-radius: 10px;
  padding: 7px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  text-align: start;
  border: 2px solid var(--purple-color);
}

.input-text-title-result-mobile {
  font-family: var(--primary-font);
  color: #adadad;
  font-size: 12px;
  width: 100%;
  height: 40px;
  background-color: #2a3038;
  border: none;
  border-radius: 10px;
  padding: 7px;
  box-sizing: border-box;
  resize: none;
  overflow: auto;
  text-align: start;
  border: 2px solid var(--purple-color);
}

.signInButton {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 18px;
  color: var(--grey-color);
  cursor: pointer;
  border-radius: 40px;
  width: 400px;
  height: 50px;
  font-family: var(--primary-font);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.signInButtonMobile {
  background-color: transparent;
  border: 1px solid var(--grey-color-2);
  font-size: 14px;
  color: var(--grey-color);
  cursor: pointer;
  border-radius: 40px;
  width: auto;
  height: 30px;
  font-family: var(--primary-font);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.character-count {
  font-family: var(--primary-font);
  margin-top: 8px;
  font-size: 16px;
  margin-right: 10px;
  width: 790px;
}

.character-count-mobile {
  font-family: var(--primary-font);
  margin-top: 8px;
  font-size: 12px;
  margin-right: 10px;
  width: auto;
}

.footer-container {
  background-color: var(--background-color);
  width: "100%";
  height: 15vh;
  padding-left: 4%;
  padding-right: 4%;
}

.footer-container-mobile {
  background-color: var(--background-color);
  width: "95%";
  height: 24vh;
  padding-left: 4%;
  padding-right: 4%;
}

.footer-subcontainer {
  background-color: var(--background-color-bis);
  display: flex;
  flex-direction: row;
  width: "100%";
  height: 12vh;
}

.footer-subcontainer-mobile {
  background-color: var(--background-color-bis);
  display: flex;
  flex-direction: column;
  width: "100%";
  height: 24vh;
}

.input-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.input-area input[type="email"] {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--grey-color-2);
  outline: none;
  border-radius: 10px;
  background-color: var(--background-color-bis);
  color: var(--light-grey-color);
  width: 250px;
  font-family: var(--primary-font);
  transition: all 0.3s ease;
}

.input-area input[type="email"]:focus {
  box-shadow: 0 0 0 2px var(--purple-color);
}

.input-area button {
  padding: 10px 20px;
  border: none;
  background-color: var(--purple-color);
  color: var(--background-color);
  cursor: pointer;
  border-radius: 4px;
  font-family: var(--primary-font);
  transition: background-color 0.3s ease;
}

.input-area button:hover {
  background-color: var(--salmon-color);
}

.topmargin {
  height: 100px;
  background-color: yellow;
}

.navbar-item {
  padding: 0 15px;
  cursor: pointer;
  color: var(--grey-color);
  transition: transform 0.2s ease-in-out;
}

.navbar-item-mobile {
  padding: 0 15px;
  cursor: pointer;
  color: var(--grey-color);
  transition: transform 0.2s ease-in-out;
  /* padding-left: 40px; */
  font-size: 24px;
  padding-bottom: 15px;
  text-align: center;
}

.navbar-item-mobile-2 {
  padding: 0 15px;
  cursor: pointer;
  color: var(--grey-color);
  transition: transform 0.2s ease-in-out;
  /* padding-left: 40px; */
  font-size: 14px;
  padding-bottom: 20px;
  text-align: center;
}

.navbar-item-mobile-3 {
  padding: 0 15px;
  padding-top: 40px;
  cursor: pointer;
  color: var(--grey-color);
  transition: transform 0.2s ease-in-out;
  /* padding-left: 40px; */
  font-size: 18px;
  padding-bottom: 20px;
  text-align: center;
}

.navbar-item:hover {
  transform: scale(1.1);
}

.navbar-item-mobile:hover {
  transform: scale(1.1);
}

.navbar-item.active {
  color: #ab73ee;
  font-weight: bold;
}

.navbar-item-mobile.active {
  color: #ab73ee;
  font-weight: bold;
}

.notifications-container {
  position: relative;
  display: inline-block;
}

.notificationButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.notification-dot {
  position: absolute;
  top: -5px;
  right: -5px;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
}

.notifications-modal {
  position: absolute;
  right: 0;
  top: 40px;
  width: 250px;
  background-color: var(--background-color-bis);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 10px;
  border-radius: 5px;
}

.notification-item {
  margin-bottom: 10px;
  color: white;
  font-size: 14px;
  font-weight: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.notification-item:last-child {
  margin-bottom: 0;
}

.notification-close-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: white;
}

.notification-item a {
  color: #ab73ee;
  text-decoration: none;
}

.notification-item a:hover {
  text-decoration: underline;
}

.profile-menu-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.profile-icon-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.profile-icon {
  display: block;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
.profile-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
}

.profile-arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
  font-size: 20px;
  color: #ab73ee;
}
.profile-arrow.open {
  transform: rotate(180deg);
}

.profile-modal {
  margin-top: 5px;
  position: absolute;
  top: 60px;
  right: 0;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 150px;
}

.profile-modal-options {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-modal-options li {
  padding: 10px;

  background-color: var(--background-color-bis);
  color: #ab73ee;
  border-radius: 5px;
}

.profile-modal-options li:hover {
  background-color: #f2f2f2;
}

.profile-modal-options li:last-child {
  border-bottom: none;
}

.notification-dot-profil {
  position: absolute;
  top: 0;
  right: 0;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
}

.toggle-button-group {
  background-color: #181b20;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.toggle-button-group-bis {
  background-color: #181b20;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.toggle-button-group-mobile2 {
  background-color: #181b20;
  color: #ffffff;
  border-radius: 10px;
  text-align: center;
  width: "100%";
}

.text-area-component-4 {
  color: #ffffff;
}

.text-area-component-2 {
  color: #ffffff;

  border-radius: 10px;
  text-align: center;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.label-button-group {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: start;
  padding-bottom: 5px;
  color: #ececec;
}

.label-button-group-mobile {
  margin-bottom: 5px;
  font-size: 12px;
  text-align: start;
  padding-bottom: 5px;
  color: #ececec;
}

.label-button-group-mobile2 {
  margin-bottom: 0px;
  font-size: 12px;
  text-align: center;
  padding-bottom: 5px;
  color: #ececec;
}

.label-button-group-2 {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: start;
  padding-bottom: 5px;
  color: #ececec;
  padding-left: 5px;
}

.label-button-group-2-mobile {
  margin-bottom: 5px;
  font-size: 12px;
  text-align: start;
  padding-bottom: 5px;
  color: #ececec;
  padding-left: 5px;
  width: 100%;
}

.button-container {
  overflow: hidden;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  scroll-behavior: smooth;
  white-space: nowrap;
  transition: transform 0.3s ease-out;
  margin-left: 0;
  width: calc(150px * 5 + 10px * 4);
}

.scroll-button-left {
  margin-top: 0px;
  width: 30px;
  height: 38px;
  color: var(--grey-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 18px;
  transition: transform 0.2s ease-in-out;
}

.scroll-button-right {
  margin-top: 0px;
  width: 30px;
  height: 38px;
  color: var(--grey-color);
  border: none;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 26px;
  transition: transform 0.2s ease-in-out;
}

.scroll-button-left:hover,
.scroll-button-right:hover {
  transform: scale(1.5);
}

.scroll-button.left {
  margin-right: 5px;
}

.scroll-button.right {
  right: 0;
}

.toggle-button {
  flex: 0 0 auto;
  border: none;
  background-color: #333;
  color: #adadad;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  font-family: var(--primary-font);
  width: 200px;
}

.toggle-button-mobile {
  flex: 0 0 auto;
  border: none;
  background-color: #333;
  color: #adadad;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 10px;
  font-family: var(--primary-font);
  width: 100px;
}

.toggle-button.selected {
  background-color: var(--purple-color);
  color: #404040;
  font-weight: bold;
  font-size: 18px;
}
.toggle-button-mobile.selected {
  background-color: var(--purple-color);
  color: #404040;
  font-weight: bold;
  font-size: 10px;
}

.gradient-button {
  background-color: transparent;
  color: transparent;
  font-size: 22px;
  border: 2px solid transparent;

  -webkit-border-image: -webkit-linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  -moz-border-image: -moz-linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  border-image: linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  font-family: var(--primary-font);
  border-radius: 30px;
  text-align: center;
  transition: background-color 0.2s, transform 0.2s;

  /* Dégradé du texte via clip */
  background-image: linear-gradient(
    90deg,
    var(--purple-color) 0%,
    var(--salmon-color) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.gradient-button-mobile {
  background-color: transparent;
  color: transparent;
  font-size: 12px;
  border: 2px solid transparent;

  -webkit-border-image: -webkit-linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  -moz-border-image: -moz-linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  border-image: linear-gradient(
      90deg,
      var(--purple-color) 0%,
      var(--salmon-color) 100%
    )
    1 stretch;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  font-family: var(--primary-font);
  border-radius: 20px;
  text-align: center;
  transition: background-color 0.2s, transform 0.2s;

  /* Dégradé du texte via clip */
  background-image: linear-gradient(
    90deg,
    var(--purple-color) 0%,
    var(--salmon-color) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
}

.gradient-button:active {
  transform: scale(0.98);
}

.email-size-adjuster {
  display: flex;
  align-items: center;
  font-family: var(--primary-font);
  color: var(--grey-color-2);
  font-size: 18px;
}

.email-size-adjuster-mobile {
  display: flex;
  align-items: center;
  font-family: var(--primary-font);
  color: var(--grey-color-2);
  font-size: 12px;
}

.email-size-adjuster button {
  background-color: var(--purple-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 18px;
}

.email-size-adjuster-mobile button {
  background-color: var(--purple-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: 0 8px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-size-adjuster span {
  color: white;
}

.email-size-adjuster-mobile span {
  color: white;
}

.button-pricing {
  margin-top: 50px;
  width: 250px;
  height: 40px;
  background-color: #d0d6de;
  border-radius: 30px;
  color: #6e7279;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.button-pricing-mobile {
  margin-top: 20px;
  width: 200px;
  height: 30px;
  background-color: #d0d6de;
  border-radius: 30px;
  color: #6e7279;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.2s, transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.button-pricing:hover {
  cursor: pointer;
  background-color: var(--purple-color);
  color: #ffffff;
}

.button-pricing:active {
  background-color: #5e0da0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.title-detail {
  padding-top: 0px;
  font-size: 14px;
  font-family: var(--primary-font);
  /* font-family: "Roboto", sans-serif;
  font-weight: 600; */
  padding-left: 5px;
  color: white;
}

.title-detail-mobile {
  padding-top: 0px;
  font-size: 12px;
  font-family: var(--primary-font);
  padding-left: 5px;
  color: white;
}

.content-detail {
  padding-left: 28px;
  font-size: 13px;
  /* font-family: var(--primary-font); */
  font-family: "Roboto", sans-serif;
}

.content-detail-mobile {
  padding-left: 28px;
  font-size: 13px;
  /* font-family: var(--primary-font); */
  font-family: "Roboto", sans-serif;
  text-align: justify;
}

.normalText {
  color: var(--purple-color);
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
  margin-left: 5px;
}

.normalTextWhite {
  color: white;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
}

.normalText-mobile {
  color: var(--purple-color);
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  margin-left: 5px;
}

.normalTextWhite-mobile {
  color: white;
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.premium-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  color: gold; /* Couleur dorée pour l'icône premium */
  font-size: 14px; /* Taille de l'icône */
  transform: translate(
    -50%,
    50%
  ); /* Ajuste la position pour être à moitié en dehors de l'image */
}
