.navbar-container {
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #181b20;
  color: white;
  /* padding-left: 80px; */
  height: 10vh;
  position: fixed;
  top: 0;
  width: 100%;
  /*     */
  /* padding-right: 20px; */
}

.navbar-left {
  display: flex;
  align-items: center;
  padding-left: 80px;
}

.navbar-left-mobile {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.navbar-right {
  display: flex;
  align-items: center;
  padding-right: 90px;
}

.navbar-right-mobile {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.app-name {
  font-size: 27px;
  padding-right: 20px;
  background: linear-gradient(
    to right,
    var(--purple-color),
    var(--salmon-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Cache la couleur de texte originale */
  -webkit-text-fill-color: transparent; /* Nécessaire pour certains navigateurs */
}

.main-title-homepage {
  background: linear-gradient(
    to right,
    var(--purple-color),
    var(--salmon-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.main-title-signup {
  /* background: linear-gradient(
    to right,
    var(--purple-color),
    var(--salmon-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.navbar-left a {
  padding-right: 40px;
  text-decoration: none;
  color: #a5a5a5; /* Couleur des liens non actifs */
  font-size: 16px; /* Taille des liens non actifs */
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-left a.active {
  color: #ad75ec; /* Couleur pour l'élément actif */
}

.navbar-right button {
  /* margin-left: 10px; */
}

.navbar-left a:hover {
  /* color: #ffffff; Optionnel: changez également la couleur au survol */
  transform: scale(1.1); /* Agrandit légèrement le lien */
}
